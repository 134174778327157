import { getUserData, updateMyPassword } from '../../API/user'
import React, { Fragment, useState } from 'react'
import { PromiseType } from 'utility-types'
import profile from 'images/dashboard/profile.jpg'
// import { Form } from 'components/Form/Form'
import { FaGithub, FaTwitter, FaInstagram, FaFacebook, FaUserEdit } from 'react-icons/fa'
// FaGithub
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { getDefaultOptions } from 'API/auth'
import { useAppSelector } from 'redux/hooks'
import { navigate } from 'gatsby-link'
import { addFileToCDNForPublic } from 'pages/course/[id]/classwork/add/addFileToCDN'

function Profile() {
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [data, setData] = React.useState<any>({})
	const [showModal, setshowModal] = useState(false)
	const once = useAppSelector((state) => state.ApplicationReducer.once)
	// const { run: runAuth } = useAuth()
	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			const userData = await getUserData()
			if (userData) {
				{
					console.log(userData)
				}
				setUserData(userData)
				setData(userData)
			}
		}
		run()
		// async function get_data() {
		// getUserData(await ApiLinks.user_data(localStorage addFileToCDNForPublic.getItem('uid')))
		// }
		// get_data()
	}, [])

	// const menuArray = [
	// 	{
	// 		id: 1,
	// 		title: 'General',
	// 		urlName: '/profile/view',
	// 	},
	// 	{
	// 		id: 2,
	// 		title: 'Edit',
	// 		urlName: '/profile/edit',
	// 	},
	// 	{
	// 		id: 3,
	// 		title: 'Password',
	// 		urlName: '/profile/password',
	// 	},
	// ]

	// const [data, setData] = React.useState<{ first_name: any; last_name: any }>({
	// 	first_name: userData?.first_name,
	// 	last_name: userData?.last_name,
	// })

	const [modalData, setModalData] = React.useState<{
		password: string
		confirmPassword: string
	}>({
		password: '',
		confirmPassword: '',
	})
	const handleChange = (e: { target: { value: string; name: string } }) => {
		setModalData((state) => ({ ...state, [e.target.name]: e.target.value }))
		console.log({ modalData })
	}

	const handleSubmit = async () => {
		if (modalData.confirmPassword !== modalData.password) {
			alert("Passwords don't match")
			return
		}
		const data = await updateMyPassword(modalData)
		if (data) {
			alert('Password changed')
			localStorage.clear()
			window !== undefined && window.location.reload()
		}
	}
	const [edit, setedit] = useState(false)
	const editbutton = () => {
		console.log(edit)
		setedit(!edit)
	}
	console.log(userData)
	// const [show, setshow] = useState(false)
	// const handleshow = () => setshow(true)
	return (
		<>
			{showModal && (
				<Modal
					show={showModal === true}
					onHide={() => {
						return
					}}
					backdrop='static'
					keyboard={false}
					size='lg'
					centered
				>
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							handleSubmit()
							// await addUser(modalData)
							// handleClose()
							// dispatch(ApplicationStateActions.setUpdate())
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Please Reset Your Password to Continue</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Row></Row>

							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtPassword'>
										<Form.Label>Password</Form.Label>
										<input
											className='form-control'
											required
											type='password'
											minLength={8}
											maxLength={32}
											placeholder='Password'
											onChange={handleChange}
											name='password'
										/>
									</Form.Group>
									<Form.Group controlId='txtPassword'>
										<Form.Label>Confirm Password</Form.Label>
										<input
											className='form-control'
											required
											type='password'
											minLength={8}
											maxLength={32}
											placeholder='Confirm Password'
											onChange={handleChange}
											name='confirmPassword'
										/>
									</Form.Group>
								</Col>
							</Row>
						</Modal.Body>

						<Modal.Footer>
							<Button
								variant='secondary'
								onClick={() => {
									setshowModal(false)
								}}
							>
								Close
							</Button>
							<Button type='submit' variant='primary'>
								Save changes
							</Button>
						</Modal.Footer>
					</form>
				</Modal>
			)}
			{userData && (
				<Fragment>
					<Modal
						show={once === false}
						onHide={() => {
							return
						}}
						backdrop='static'
						keyboard={false}
						size='lg'
						centered
					>
						<form
							onSubmit={async (e) => {
								e.preventDefault()
								handleSubmit()
								// await addUser(modalData)
								// handleClose()
								// dispatch(ApplicationStateActions.setUpdate())
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title>Please Reset Your Password to Continue</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<Row></Row>

								<Row>
									<Col lg={6}>
										<Form.Group controlId='txtPassword'>
											<Form.Label>Password</Form.Label>
											<input
												className='form-control'
												required
												type='password'
												minLength={8}
												maxLength={32}
												placeholder='Password'
												onChange={handleChange}
												name='password'
											/>
										</Form.Group>
										<Form.Group controlId='txtPassword'>
											<Form.Label>Confirm Password</Form.Label>
											<input
												className='form-control'
												required
												type='password'
												minLength={8}
												maxLength={32}
												placeholder='Confirm Password'
												onChange={handleChange}
												name='confirmPassword'
											/>
										</Form.Group>
									</Col>
								</Row>
							</Modal.Body>

							<Modal.Footer>
								<Button
									variant='secondary'
									onClick={() => {
										setshowModal(false)
									}}
								>
									Close
								</Button>
								<Button type='submit' variant='primary'>
									Save changes
								</Button>
							</Modal.Footer>
						</form>
					</Modal>
					{!userData?.profile_blocked && (
						<Button className='btn-lg mb-3' onClick={editbutton}>
							<span className='mr-2'>{edit ? 'Back' : 'Edit'}</span>
							{!edit && <FaUserEdit />}
						</Button>
					)}
					{!edit && (
						<section style={{ backgroundColor: '#eee' }}>
							<div className='container py-5'>
								<div className='row'>
									<div className='col-lg-4'>
										<div className='card mb-4'>
											<div className='card-body text-center'>
												{userData.photo_file.photo_file === '' && (
													<img
														src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp'
														alt='Profile Img'
														className='rounded-circle img-fluid'
														style={{ width: 150 }}
													/>
												)}
												{userData.photo_file.photo_file !== '' && (
													<img
														src={`${
															userData?.photo_file.photo_file
																? getUrl(
																		`static/${userData?.photo_file.photo_file}?token=${userData.photo_file.photo_token}`
																  )
																: ''
														}`}
														alt='Profile Img'
														className='rounded-circle img-fluid'
														style={{ width: 150 }}
													/>
												)}
												<h5 className='my-3'>
													{userData.first_name} {userData.last_name}
												</h5>
												<p className='text-muted'>{userData.username}</p>
												<p className='text-muted mb-1'>{userData.email}</p>
												<p className='text-muted mb-4'>
													{userData.country} {userData.city}
												</p>
												{!userData?.profile_blocked && (
													<div
														style={{ color: 'white' }}
														className='content py-1 px-2 rounded btn btn-primary'
														onClick={() => {
															setshowModal(true)
														}}
													>
														Reset Password
													</div>
												)}
											</div>
										</div>

										<div className='card mb-4 mb-lg-0'>
											<div className='card-body p-0'>
												<ul className='list-group list-group-flush rounded-3'>
													{/* <li className='list-group-item d-flex justify-content-between align-items-center p-3'>
														<FaGithub style={{ color: '#333333' }} />
														<p className='mb-0'>git</p>
													</li> */}
													<li className='list-group-item d-flex justify-content-between align-items-center p-3'>
														<FaTwitter style={{ color: '#55acee' }} />
														{userData.twitter === '' && <div>Empty</div>}
														{userData.twitter !== '' && (
															<a target='_blank' href={'https://twitter.com/' + userData.twitter} rel='noreferrer'>
																Link
															</a>
														)}
													</li>
													<li className='list-group-item d-flex justify-content-between align-items-center p-3'>
														<FaInstagram style={{ color: '#ac2bac' }} />
														{userData.instagram === '' && <div>Empty</div>}
														{userData.instagram !== '' && (
															<a
																target='_blank'
																href={'https://www.instagram.com/' + userData.instagram}
																rel='noreferrer'
															>
																Link
															</a>
														)}
													</li>
													<li className='list-group-item d-flex justify-content-between align-items-center p-3'>
														<FaFacebook style={{ color: '#3b5998' }} />
														<p className='mb-0'>
															{userData.facebook === '' && <div>Empty</div>}
															{userData.facebook != '' && (
																<a target='_blank' href={'https://' + userData.facebook} rel='noreferrer'>
																	Link
																</a>
															)}
														</p>
													</li>
												</ul>
											</div>
											{/* {userData.resume_link !== '' && <div className='card-body p-0'></div>} */}
										</div>
									</div>
									<div className='col-lg-8'>
										<div className='card mb-4'>
											<div className='card-body'>
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Full Name</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>
															{userData.first_name} {userData.last_name}
														</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Email</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.email}</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Batch year</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.year === '' ? 'empty' : userData.year}</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Class</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.class === '' ? 'empty' : userData.class}</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Roll number</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.roll_num === '' ? 'empty' : userData.roll_num}</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Enrollment number</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>
															{userData.enrollment_num === '' ? 'empty' : userData.enrollment_num}
														</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Mobile</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.mobileNo === '' ? 'empty' : userData.mobileNo}</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Website Link</p>
													</div>
													<div className='col-sm-9'>
														<p className='mb-0'>
															{userData.website_link === '' ? (
																'empty'
															) : (
																<a target='_blank' href={userData.website_link} rel='noreferrer'>
																	Link
																</a>
															)}
														</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>E-portfolio</p>
													</div>
													<div className='col-sm-9'>
														<p className='mb-0'>
															{userData.e_portfolio_link === '' ? (
																'empty'
															) : (
																<a target='_blank' href={userData.e_portfolio_link} rel='noreferrer'>
																	Link
																</a>
															)}
														</p>
													</div>
												</div>
												<hr />
												<div className='row'>
													<div className='col-sm-3'>
														<p className='mb-0'>Address</p>
													</div>
													<div className='col-sm-9'>
														<p className='text-muted mb-0'>{userData.address === '' ? 'empty' : userData.address}</p>
													</div>
												</div>
											</div>
										</div>

										{/* <iframe src={userData.resume} frameBorder='100' height={100} width={100}></iframe> */}
										{/* <div className='row'>
											<div className='col-md-12'>
												<div className='card mb-4 mb-md-0'>
													<div className='card-body'>
														<p className='mb-4'>
															<span className='text-primary font-italic me-1'>Skills</span>
														</p>
														<p className='mb-1' style={{ fontSize: '.77rem' }}>
															Web Design
														</p>
														<div className='progress rounded' style={{ height: 5 }}>
															<div
																className='progress-bar'
																role='progressbar'
																style={{ width: '80%' }}
																aria-valuenow={80}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
														<p className='mt-4 mb-1' style={{ fontSize: '.77rem' }}>
															Website Markup
														</p>
														<div className='progress rounded' style={{ height: 5 }}>
															<div
																className='progress-bar'
																role='progressbar'
																style={{ width: '72%' }}
																aria-valuenow={72}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
														<p className='mt-4 mb-1' style={{ fontSize: '.77rem' }}>
															One Page
														</p>
														<div className='progress rounded' style={{ height: 5 }}>
															<div
																className='progress-bar'
																role='progressbar'
																style={{ width: '89%' }}
																aria-valuenow={89}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
														<p className='mt-4 mb-1' style={{ fontSize: '.77rem' }}>
															Mobile Template
														</p>
														<div className='progress rounded' style={{ height: 5 }}>
															<div
																className='progress-bar'
																role='progressbar'
																style={{ width: '55%' }}
																aria-valuenow={55}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
														<p className='mt-4 mb-1' style={{ fontSize: '.77rem' }}>
															Backend API
														</p>
														<div className='progress rounded mb-2' style={{ height: 5 }}>
															<div
																className='progress-bar'
																role='progressbar'
																style={{ width: '66%' }}
																aria-valuenow={66}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</div>
												</div>
											</div>
										</div> */}
									</div>
									<div className='col-lg-12 mt-2'>
										{userData.resume_file.resume_file !== '' && (
											<iframe
												title='Resume'
												src={`${
													userData?.resume_file.resume_file
														? getUrl(
																`static/${userData?.resume_file.resume_file}?token=${userData.resume_file.resume_token}`
														  )
														: ''
												}#toolbar=0`}
												style={{ width: '100%', height: '30rem' }}
											></iframe>
										)}
									</div>
								</div>
							</div>
						</section>
					)}
					{edit && (
						<div className='pt-2 pb-2' style={{ backgroundColor: '#eee' }}>
							<div className='container mt-5'>
								<form
									onSubmit={async (e) => {
										e.preventDefault()
										console.log({ data })
										const result = await handleApi(
											axios.post(
												getUrl('user/update_user_detail'),
												{
													first_name: data.first_name,
													last_name: data.last_name,
													mobileNo: data.mobileNo,
													address: data.address,
													facebook: data.facebook,
													instagram: data.instagram,
													resume: data.resume,
													photo: data.photo,
													year: data.year,
													enrollment_num: data.enrollment_num,
													roll_num: data.roll_num,
													class: data.class,
													twitter: data.twitter,
													e_portfolio_link: data.e_portfolio_link,
													website_link: data.website_link,
												},
												{ ...getDefaultOptions() }
											)
										)
										if (result) {
											alert('Profile Updated')
											window.location.reload()
										} else {
											alert('Some Error Occurred. Please Try Again')
										}
									}}
								>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label htmlFor='inputEmail4'>Email</label>
											<input
												disabled
												value={userData.email}
												type='email'
												className='form-control'
												id='inputEmail4'
												placeholder='Email'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label htmlFor='Username'>Username</label>
											<input disabled type='text' value={userData.username} className='form-control' placeholder='Username' />
										</div>
									</div>
									<div className='form-group'>
										<label htmlFor='inputAddress'>Address</label>
										<input
											defaultValue={data.address}
											onChange={(e) => {
												setData({ ...data, address: e.target.value })
											}}
											type='text'
											className='form-control'
											id='inputAddress'
											placeholder='Address'
										/>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>First Name</label>
											<input
												defaultValue={data.first_name}
												type='link'
												className='form-control'
												onChange={(e) => {
													setData({ ...data, first_name: e.target.value })
													// setUser({ ...user, first_name: e.target.value })
												}}
												placeholder='First Name'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>Last Name</label>
											<input
												defaultValue={data.last_name}
												onChange={(e) => {
													setData({ ...data, last_name: e.target.value })
												}}
												type='link'
												className='form-control'
												placeholder='Last Name'
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Mobile Number</label>
											<input
												type='number'
												defaultValue={data.mobileNo}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, mobileNo: e.target.value })
												}}
												placeholder='number'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>Batch year</label>
											<input
												type='text'
												defaultValue={data.year}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, year: e.target.value })
												}}
												placeholder='year'
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Class</label>
											<input
												type='text'
												defaultValue={data.class}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, class: e.target.value })
												}}
												placeholder='Enter your Class'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>Roll Number</label>
											<input
												type='text'
												defaultValue={data.roll_num}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, roll_num: e.target.value })
												}}
												placeholder='Roll number'
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Enrollment Number</label>
											<input
												type='text'
												defaultValue={data.enrollment_num}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, enrollment_num: e.target.value })
												}}
												placeholder='Enter your enrollment number'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>Twitter</label>
											<input
												type='text'
												defaultValue={data.twitter}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, twitter: e.target.value })
												}}
												placeholder='Twitter Username'
											/>
										</div>
									</div>
									{/* <div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Git Hub</label>
											<input type='link' className='form-control' placeholder='link' />
										</div>
										<div className='form-group col-md-6'>
											<label>Twitter</label>
											<input type='link' className='form-control' placeholder='link' />
										</div>
									</div> */}
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Website Link</label>
											<input
												defaultValue={data.website_link}
												type='text'
												className='form-control'
												onChange={(e) => {
													setData({ ...data, website_link: e.target.value })
												}}
												placeholder='Website Link'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>E-portfolio Link</label>
											<input
												type='link'
												defaultValue={data.e_portfolio_link}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, e_portfolio_link: e.target.value })
												}}
												placeholder='E-portfolio link'
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label>Instagram</label>
											<input
												defaultValue={data.instagram}
												type='text'
												className='form-control'
												onChange={(e) => {
													setData({ ...data, instagram: e.target.value })
												}}
												placeholder='Instagram Username'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>Facebook</label>
											<input
												type='link'
												defaultValue={data.facebook}
												className='form-control'
												onChange={(e) => {
													setData({ ...data, facebook: e.target.value })
												}}
												placeholder='Facebook Profile Link'
											/>
										</div>
									</div>
									{/* NOT IN USE NOW */}
									{/* <div>
										<div className='form-group'>
											<label>Skills* (Separate each skill with a space and a comma)</label>
											<input
												type='text'
												name='skills'
												className='form-control'
												// defaultValue={values.status === 1 ? '' : values.skills}
												// onChange={handleChange}
											/>
										</div>

										<div className='card-body'>
											<h3 className='card-title'>Experience Info</h3>
											<hr />
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>1</b>
												</h3>
											</div>

											<div className='col-lg-4 text-left'>
												<label>Institute/Organisation*</label>
												<input
													type='text'
													name='exp1_org'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp1_org}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Position*</label>
												<input
													type='text'
													name='exp1_pos'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp1_pos}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Duration*</label>
												<input
													type='text'
													name='exp1_dur'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp1_dur}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description*</label>
												<input
													type='text'
													name='exp1_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp1_desc}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>

										<br />

										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>2</b>
												</h3>
												<hr />
											</div>
											<div className='col-lg-4 text-left'>
												<label>Institute/Organisation*</label>
												<input
													type='text'
													name='exp2_org'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp2_org}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Position*</label>
												<input
													type='text'
													name='exp2_pos'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp2_pos}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Duration*</label>
												<input
													type='text'
													name='exp2_dur'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp2_dur}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description*</label>
												<input
													type='text'
													name='exp2_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.exp2_desc}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>
										<br />
										<div className='card-body'>
											<h3 className='card-title'>Projects Info</h3>
											<hr />
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>1</b>
												</h3>
											</div>
											<div className='col-lg-6 text-left'>
												<label>Title*</label>
												<input
													type='text'
													name='proj1_title'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj1_title}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-6 text-left'>
												<label>Link</label>
												<input
													type='text'
													name='proj1_link'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj1_link}
													// onChange={handleChange}
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description*</label>
												<input
													type='text'
													name='proj1_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj1_desc}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>

										<br />

										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>2</b>
												</h3>
												<hr />
											</div>
											<div className='col-lg-6 text-left'>
												<label>Title*</label>
												<input
													type='text'
													name='proj2_title'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj2_title}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-6 text-left'>
												<label>Link</label>
												<input
													type='text'
													name='proj2_link'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj2_link}
													// onChange={handleChange}
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description*</label>
												<input
													type='text'
													name='proj2_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.proj2_desc}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>
										<br />
										<div className='card-body'>
											<h3 className='card-title'>Education Info</h3>
											<hr />
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>1</b>
												</h3>
											</div>
											<div className='col-lg-4 text-left'>
												<label>College/University*</label>
												<input
													type='text'
													name='edu1_school'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu1_school}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Year*</label>
												<input
													type='text'
													name='edu1_year'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu1_year}
													// onChange={handleChange}
													required
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Qualification*</label>
												<input
													type='text'
													name='edu1_qualification'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu1_qualification}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description*</label>
												<input
													type='text'
													name='edu1_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu1_desc}
													// onChange={handleChange}
													required
												/>
											</div>
										</div>

										<br />

										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<h3>
													<b>2</b>
												</h3>
												<hr />
											</div>
											<div className='col-lg-4 text-left'>
												<label>School</label>
												<input
													type='text'
													name='edu2_school'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu2_school}
													// onChange={handleChange}
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Year</label>
												<input
													type='text'
													name='edu2_year'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu2_year}
													// onChange={handleChange}
												/>
											</div>
											<div className='col-lg-4 text-left'>
												<label>Qualification</label>
												<input
													type='text'
													name='edu2_qualification'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu2_qualification}
													// onChange={handleChange}
												/>
											</div>
										</div>
										<div className='row col-lg-10 mx-auto'>
											<div className='col-lg-12 text-left'>
												<label>Description</label>
												<input
													type='text'
													name='edu2_desc'
													className='form-control'
													// defaultValue={values.status === 1 ? '' : values.edu2_desc}
													// onChange={handleChange}
												/>
											</div>
										</div>
										<br />
									</div> */}
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label className='mr-2'>Upload Resume </label>
											<input
												type='file'
												className='form-control'
												onChange={async (e) => {
													if (e.target.files !== null) {
														if (e.target.files[0].type !== 'application/pdf') {
															alert('Please Upload in PDF format')
														} else {
															const resume = await addFileToCDNForPublic({
																file: e.target.files[0],
																name: e.target.files[0].name,
																public: true,
															})
															if (resume) {
																setData({ ...data, resume: resume._id })
															}
														}
													}
												}}
												placeholder='Please Upload your resume in PDF format only'
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label className='mr-2'>Upload Profile Photo </label>
											<input
												type='file'
												className='form-control'
												onChange={async (e) => {
													if (e.target.files !== null) {
														if (!e.target.files[0].type.match('image.*')) {
															alert('Please Upload Image')
														} else {
															const photo = await addFileToCDNForPublic({
																file: e.target.files[0],
																name: e.target.files[0].name,
																public: true,
															})
															if (photo) {
																setData({ ...data, photo: photo._id })
															}
														}
													}
												}}
												placeholder='Please Upload your resume in PDF format only'
											/>
										</div>
									</div>
									<button type='submit' className='btn btn-primary mb-2'>
										Update
									</button>
								</form>
							</div>
						</div>
					)}
					{/* OLD */}
					{/* <div className='row mx-auto'>
						<div className='col-md-3 my-2 mx-auto'>
							<div className='card viewCard border  shadow-sm'>
								<div className='card-header cardProfileHeader'></div>

								<img src={profile} alt='Profile Img' height='80' width='80' className='rounded-circle  profileImg  mx-auto mb-0' />
								<label className='mx-auto my-1'>{userData.username}</label>

								<h6 className='mx-auto my-2'>
									{userData.first_name} {userData.last_name}
								</h6>

								<div className='mx-auto mt-1 mb-3'>
									{userData.country}, {userData.city}
								</div>
							</div>
						</div>
						<div className='col-md-9 my-1'>
							<div className='viewProfile mx-auto p-4'>
								<div className='profileContent mx-lg-auto my-3'>
									<h5 style={{ color: 'rgb(50, 50, 93)' }} className=' pb-3'>
										User Information
									</h5>
									<div className='row'>
										<div className='viewContent my-2  col-md-6'>
											<div className='font-weight-normal px-1 py-1 text-muted viewContentTitle'>Username</div>
											<div className='content py-1 px-2 text-muted rounded '>{userData.username}</div>
										</div>

										<div className='viewContent my-2  col-md-6'>
											<div className='font-weight-normal px-1 py-1 text-muted viewContentTitle'>Email</div>
											<div className='content py-1 px-2 text-muted rounded '>{userData.email}</div>
										</div>
									</div>
									<form
										onSubmit={async (e) => {
											e.preventDefault()
											console.log({ data })
											await handleApi(
												axios.post(
													getUrl('user/update_user_detail'),
													{ first_name: data.first_name, last_name: data.last_name },
													{ ...getDefaultOptions() }
												)
											)
											alert('Data Updated')
											window.location.reload()
										}}
										className='form-inline'
									>
										<div className='form-group mb-2'>
											<label htmlFor='firstName' className='sr-only'>
												First Name
											</label>
											<input
												type='text'
												className='form-control'
												id='firstName'
												placeholder='First Name'
												defaultValue={data.first_name}
												onChange={(e) => {
													setData({ ...data, first_name: e.target.value })
													// setUser({ ...user, first_name: e.target.value })
												}}
											/>
										</div>
										<div className='form-group mx-sm-3 mb-2'>
											<label htmlFor='lastName' className='sr-only'>
												Last Name
											</label>
											<input
												type='text'
												className='form-control'
												id='lastName'
												placeholder='Last Name'
												defaultValue={data.last_name}
												onChange={(e) => {
													setData({ ...data, last_name: e.target.value })
												}}
											/>
										</div>
										<button type='submit' className='btn btn-primary mb-2'>
											Update
										</button>
									</form>

									<div className='row border-bottom pb-3'>
										<div className='viewContent my-2  col-md-6'>
											<div className='font-weight-normal px-1 py-1 text-muted viewContentTitle'>Reset Password</div>
											<div
												style={{ color: 'white' }}
												className='content py-1 px-2 rounded btn btn-primary'
												onClick={() => {
													navigate('/forgot_password')
												}}
											>
												Reset
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</Fragment>
			)}
		</>
	)
}

export default Profile
